* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Encode Sans', sans-serif;
}

.vertical-timeline::before {
  /* this is the vertical line */
  background: #01bf71 !important;
}
.vertical-timeline-element-content {
  border-style: solid !important;
  border-radius: 20px !important;
  border-width: 1px !important;
  border-color: #01bf71 !important;
  padding: 2em 3em !important;
  box-shadow:  0px 0px 5px 2px #ddd !important;
}

.date {
  font-weight: 600 !important;
  color: #01bf71 !important;
}